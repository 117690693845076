/* You can add global styles to this file, and also import other style files */

@import "~@angular/material/theming";
@import "/src/app/presets/containers.scss";
@include mat-core();
$primary: mat-palette($mat-blue, 800);
$accent: mat-palette($mat-red, 900);
$theme: mat-light-theme($primary, $accent);
@include angular-material-theme($theme);
@import "~ng-pick-datetime/assets/style/picker.min.css";

:root {
  --main-red: #d71212;
  --main-green: #2e7434;
}

html,
body {
  height: 100%;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

html {
  scroll-behavior: smooth;
}

ol,
ul {
  list-style: none;
  padding: 0;
}

a {
  cursor: pointer;
  text-decoration: none;
}

button {
  transition: all 0.3s ease-in-out;
}

mat-radio-group mat-radio-button {
  margin: 0 10px;
}

.mat-radio-button.mat-accent .mat-radio-inner-circle,
.mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple),
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple,
.mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: #b22e2f;
}

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #b22e2f;
}

.mat-radio-button:not(.mat-radio-disabled).cdk-keyboard-focused .mat-radio-persistent-ripple,
.mat-radio-button:not(.mat-radio-disabled).cdk-program-focused .mat-radio-persistent-ripple,
.mat-radio-container:hover .mat-radio-persistent-ripple,
.mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple) {
  opacity: 0 !important;
}

.mat-radio-label,
.mat-checkbox-layout {
  white-space: normal !important;
}

#payments {
  .mat-radio-label-content {
    width: 100% !important;
  }
}

// .mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
//     color: #275229 !important;
// }
// .mat-form-field-appearance-outline .mat-form-field-outline-thick {
//     color: #275229 !important;
// }
// .mat-form-field-appearance-outline .mat-form-field-outline {
//     color: #275229 !important;
// }
// #print-section.column-info {
//   display: flex;
//   margin: 0 auto;
//   width: 800px;
//   flex-wrap: wrap;
//   .column-data {
//     // flex: 0 0 50%;
//     width: 400px;
//   }
//   .parraf {
//     font-size: 18px;
//     margin-bottom: 10px;
//     width: 400px;
//     strong {
//       font-weight: 500;
//     }
//   }
// }
.mat-form-field-type-mat-select:not(.mat-form-field-disabled) .mat-form-field-flex {
  background: white;
}

.mat-form-field-underline {
  display: none;
}

.mat-form-field-appearance-fill .mat-form-field-flex {
  background: white;
}

.mat-menu-content {
  background-color: rgb(237, 237, 237);
  max-width: min-content !important;
}

owl-date-time-container {
  border-radius: 12px !important;
  opacity: 0.95 !important;
  transform: scale(0.9, 0.9) !important;
  .owl-dt-container-buttons {
    color: rgb(43, 110, 235);
  }
  .owl-dt-timer-hour12 .owl-dt-timer-hour12-box {
    border: 1px solid rgb(43, 110, 235);
    background: rgb(43, 110, 235);
    color: white;
  }
  .owl-dt-timer-hour12 .owl-dt-timer-hour12-box:hover .owl-dt-control-button-content {
    background: rgb(43, 110, 235);
  }
}

.mat-menu-panel {
  max-width: none !important;
}

.paypal-button-container {
  margin: 0 auto !important;
}
